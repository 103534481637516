import React from 'react';
import styled from 'styled-components';

import SvgLoading from "../images/loading.svg";

const Container = styled.div`
    display: block;
    width: 100%;
    margin: 1em;
`
const Graphic = styled.img`
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto;
`

interface IProps {

}

function Loading({}: IProps) {
    return(
        <Container>
            <Graphic src={SvgLoading} title={`Loading`} alt={`Loading`} />
        </Container>
    )
}

export default Loading;