import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

export const aniRainbowText = keyframes`
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-360deg);
  }
`
const Text = styled.span`
  background-image: -webkit-linear-gradient(
    120deg,
    #BE416D,
    #ABBE41,
    #41BE92,
    #5441BE
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: 10s linear 0s infinite normal none running ${aniRainbowText};
`

interface IProps {
    text: string;
    id?: string;
}

function TextRainbow({ text, id }: IProps) {
  return(
    <Text id={id ?? ''}>
      {text}
    </Text>
  )
}

TextRainbow.propTypes = {
  text: PropTypes.string.isRequired
};

export default TextRainbow;