import React, { useState } from "react"
import styled from "styled-components"

import TextRainbow from "../../../components/TextRainbow"
import Loading from "../../../components/Loading"

const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const Button = styled.div`
    border-radius: 5px;
    padding: 0.5em;
    margin: 2em auto;
    background: #FFFFCF;
    color: #212121;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
`
const TweetContainer = styled.div`
    width: fit-content;
    margin: auto auto;
    padding: 1em 0;
`
const TweetBox = styled.textarea`
    display: block;
    border-radius: 5px;
    padding: 0.25em;
    resize: none;
`
const Error = styled.div`
    padding: 0.25em;
    width: 100%;
    background: #FF6961;
    color: #FFF;
    font-weight: bolder;
    margin: 1em;
`

const ConstructTweet = (props: any) => {
    const ENDPOINT = process.env.NODE_ENV !== 'production' ? 'http://localhost:8888/' : 'https://api.harrydenley.com/'
    const { web3, ensLabel } = props;
    const refTweetText = React.useRef<any>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [charsLeft, setCharsLeft] = useState<number>(250);

    const handleClick = async () => {
        const tweet = refTweetText.current?.value.trim();

        if(tweet.length === 0) {
            return;
        }

        const formattedMessage = `KEEPER:{${ensLabel}}MSG:{${tweet}}`
        const sig = await props.web3.signer.signMessage(formattedMessage)
            .catch((error: any) => {
                setError(error.message);
            });
            
        setIsLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                address: web3.activeAddress,
                message: formattedMessage,
                signature: sig
            })
        };
        fetch(`${ENDPOINT}ethereum/ens-time-keepers/post`, requestOptions)
            .then(response => response.json())
            .then(res => {
                setIsLoading(false);
                if(res.response === "ERROR") {
                    setError(res.message);
                    return;
                }

                props.callback({
                    nextStep: 3,
                    tweetId: res.data.tweet_id,
                    tweetText: tweet
                })
            })
            .catch((error) => {
                setError(error.message);
            })
    }

    const renderView = () => {
        if(isLoading) {
            return(<Loading />)
        }

        return(
            <>
                {error != "" && <Error>{error}</Error>}

                <TweetContainer>
                    <TweetBox 
                        ref={refTweetText}
                        cols={50} 
                        rows={5} 
                        placeholder={`Your tweet to send to the world!`}
                        onKeyUp={(ev) => setCharsLeft(250 - (ev.target as HTMLTextAreaElement).value.length)}
                    ></TweetBox>
                    Chars remaining: {charsLeft}
                </TweetContainer>

                <Button onClick={() => handleClick()}>
                    Verify and Tweet!
                </Button>
            </>
        )
    }

    return (<>
        <SubTitle>
            Type the tweet you want to send, then sign the details with your Ethereum wallet, and ✨voila!✨
        </SubTitle>

        Address: <TextRainbow text={web3 && web3.hasOwnProperty("activeAddress") ? web3.activeAddress : ""} /> <br />
        #TimeKeeper: <TextRainbow text={ensLabel} /> <br /><br />

       {renderView()}
    </>)
}

export default ConstructTweet